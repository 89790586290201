@import '../../../assets/styles/colors';

.card {
  margin: 20px 0;
  border: 1px solid $color-gray-theme-border;
  border-radius: 6px;
  text-align: center;

  & + .card {
    margin-top: 0;
  }
}

.container {
  padding: 10px;
}

.noMargin {
  margin-top: 0;
}

.button {
  width: 100%;
  padding: 16px 20px;
  background: transparent;
  border-top: 1px solid $color-gray-theme-border;
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($color-gray-theme-border, 0.5);
    outline: none;
  }

  &:hover {
    background: lighten($color-gray-theme-border, 8%);

    &:disabled {
      background: transparent;
    }
  }

  &:active {
    background: $color-gray-theme-border;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.btn-insurance {
  color: _UIColor(insurance);
}

.btn-marketplace {
  color: darken(_UIColor(marketplace), 10%);
}

.btn-personalFinance {
  color: darken(_UIColor(personalFinance), 15%);
}

.btn-rental {
  color: darken(_UIColor(rental), 10%);
}
