@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.partnerCardComponent {
  background-color: $color-white;
  box-shadow: 0 15px 25px 0 rgba(218, 225, 232, 0.7);
  padding: 25px;
  height: 100%;
}

.logoWrap {
  height: 40px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  max-height: 40px;
  max-width: 120px;
}

.badgeWrap {
  align-self: flex-start;
}

.name {
  font-family: $font-poppins;
  font-size: 15px;
  line-height: 23px;
  font-weight: 500;
  color: $color-black;
  margin-bottom: 5px;
}

.industry {
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.66px;
  color: $color-gray;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.description {
  font-size: 15px;
  line-height: 23px;
  color: $color-gray;
}

.link {
  font-size: 15px;
  line-height: 23px;
  margin-top: 15px;
}
