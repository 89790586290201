$theme-colors: (
  'primary': $color-gray,
  'secondary': $color-orange,
  'light': $color-white,
  'dark': $color-black,
);
$link-color: $color-orange;

@import 'node_modules/bootstrap/scss/bootstrap';

a {
  text-decoration: underline;
}

.container {
  padding-top: 35px;
  padding-bottom: 35px;

  &__center-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
}

.progress {
  height: 1px;
  background-color: $color-purple-darker;
  border-radius: 0;

  &.gray {
    background-color: $color-light-gray;
  }
}

.progress-bar {
  background-image: $gradient-main;
}

.btn {
  border-radius: 5px;
  padding: 16px 39px;
  cursor: pointer;

  &.disabled,
  &:disabled {
    cursor: not-allowed;
  }
}

.btn-outline-light:hover {
  path {
    fill: $color-black;
  }
}

.btn-pill-gray,
.btn-pill-white {
  font-family: $font-poppins;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: 8px 16px;
  margin: 0 10px;
  border-radius: 17px !important;

  &.active {
    color: $color-dark-gray;

    &:hover {
      color: $color-dark-gray;
    }
  }
}

.btn-pill-gray {
  color: $color-gray;

  &:hover {
    color: $color-gray;
  }

  &.active {
    background-color: $color-light-gray;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $input-btn-focus-width darken($color-light-gray, 5%);
  }
}

.btn-pill-white {
  color: $color-white;

  &:hover {
    color: $color-white;
  }

  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  }

  &.active {
    background-color: $color-white;
  }
}

.btn-card {
  font-family: $font-poppins;
  color: $color-black;
  font-size: 15px;
  line-height: 23px;
  font-weight: 500;
  background-color: $color-white;
  box-shadow: 0 15px 25px 0 rgba(218, 225, 232, 0.7);
  border: 2px solid $color-white;
  border-radius: 0;
  padding: 30px;
  width: 45%;
  margin: 0 15px 30px 15px !important;

  &.focus,
  &.active {
    border: 2px solid $color-orange-light;
    box-shadow: 0 15px 25px 0 rgba(218, 225, 232, 0.7);
  }

  @media (max-height: 750px) {
    padding: 25px;
  }
}

.btn-dwolla {
  @include button-variant($color-purple, $color-purple);
}

.btn-insurance {
  @include button-variant(_UIColor(insurance), _UIColor(insurance));
}

.btn-marketplace {
  @include button-variant(_UIColor(marketplace), _UIColor(marketplace));
}

.btn-personalFinance {
  @include button-variant(_UIColor(personalFinance), _UIColor(personalFinance));
}

.btn-rental {
  @include button-variant(_UIColor(rental), _UIColor(rental));
}

.form-label {
  font-size: 12px;
  color: $color-gray;
  text-transform: uppercase;
}

.form-control,
.input-group-text {
  font-size: 14px;
}
