@import '../../../assets/styles/mixins';

.themePickerButtonComponent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: left;
}

.logo {
  width: 42px;
}

.label {
  margin: 5px 0;
}

.colors {
  display: flex;
  margin-left: -4px;
  margin-right: -4px;
}

.color {
  width: 47px;
  height: 35px;
  margin: 0 4px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.primary {
  @include getThemeColors('primary');
}
.secondary {
  @include getThemeColors('secondary');
}
.tertiary {
  @include getThemeColors('tertiary');
}
.quaternary {
  @include getThemeColors('quaternary');
}
