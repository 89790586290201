@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/bootstrap-mixins';

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-height: $phone-max-height;
  flex-grow: 1;
}

.themePickerWrap {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-right: 25px;

  @include media-breakpoint-up(lg) {
    width: 70%;
    padding-right: 0;
  }
}

.phoneWrap {
  width: 40%;
  position: relative;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(lg) {
    width: 30%;
  }
}

.phoneImage {
  position: absolute;
  transition: background-image 400ms $ease-out-expo;
}

.imageLink {
  display: block;
  width: 100%;
  height: 100%;
}
