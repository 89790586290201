@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.steps {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  max-width: 620px;
  max-height: 500px;
  margin-top: 15px;
}

.stepNumber {
  position: relative;
  margin-right: 25px;
  font-family: $font-poppins;
  color: $color-gray;
  font-size: 40px;
  line-height: 45px;
}

.stepCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border: 1px solid $color-orange-light;
  border-radius: 100%;
}

.stepLine {
  position: absolute;
  left: 37px;
  top: 75px;
  bottom: 0;
  border-left: 1px solid $color-orange-light;
}

.stepText {
  width: calc(100% - 100px);
  font-family: $font-roboto;
}

.stepName {
  margin-bottom: 10px;
  color: $color-black;
  font-size: 23px;
}

.stepDescription {
  margin-bottom: 40px;
  color: $color-gray;
  font-size: 15px;
  line-height: 23px;
}
