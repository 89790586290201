@import '../../../assets/styles/variables';

.videoLoop {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 800ms $ease-out-expo;
  opacity: 0;
}

.selected {
  opacity: 1;
}
