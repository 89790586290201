@import '../../../assets/styles/colors';
@import '../../../assets/styles/bootstrap-mixins';

.dashboardViewComponent {
  height: calc(100% - 45px);
  max-height: 326px;
}

.content {
  position: relative;
  max-width: 522px;
  height: 100%;
  border-bottom: 1px solid $color-purple-border;
  border-radius: 5px;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    border-bottom: none;
    overflow: visible;
  }
}

.image {
  position: absolute;
  max-width: 100%;
}

.link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button {
  white-space: nowrap;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
}
