@import '../../../assets/styles/bootstrap-mixins';

.buttonToolbar {
  flex-grow: 1;
  max-height: 500px;
  max-width: 800px;
  padding-left: 25px;
  margin-left: -25px;
  margin-top: 10px;

  @include media-breakpoint-down(md) {
    overflow: auto;
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -30px;
  width: 100%;
}
