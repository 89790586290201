@import '../../../assets/styles/colors';
@import '../../../assets/styles/keyframes';

.demoSelectPage {
  background-image: $gradient-light;
  color: $color-white;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttons {
  animation: fadeIn 800ms linear 1 forwards;
}

.layers {
  margin-top: 40px;
}
