@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/bootstrap-mixins';

.progressBarComponent {
  color: $color-white;
  background-color: $color-purple-dark;

  @include media-breakpoint-up(md) {
    height: 125px;

    @media (max-height: 750px) {
      height: 79px;
    }
  }

  &.gray {
    color: $color-dark-gray;
    background-color: $color-gray-background;
  }
}
