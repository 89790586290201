@import '../../../assets/styles/colors';

.homePage {
  background-image: $gradient-light;
  color: $color-white;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 76px;
}
