@import '../../../assets/styles/colors';

.conclusionPage {
  background-image: $gradient-light;
  color: $color-white;
}

.card {
  background-color: $color-purple;
  padding: 25px;
  border-radius: 6px;
  max-width: 420px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1,
  h2,
  a {
    color: $color-white;
  }

  a {
    margin-top: 25px;
  }
}
