@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.phoneHeaderComponent {
  width: 100%;
  display: flex;
  align-items: center;
}

.logo {
  padding: 10px 15px 10px 10px;
}

.dummyText {
  width: 89px;
  height: 8px;
  border-radius: 6px;
  background-color: $color-white;
}

.insurance {
  background-color: map-get($palette-insurance, secondary);
}

.marketplace {
  background-color: map-get($palette-marketplace, primary);
}

.personalFinance {
  background-color: map-get($palette-personal-finance, quaternary);
}

.rental {
  background-color: map-get($palette-rental, quaternary);
}
