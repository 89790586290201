/* Colors */
$color-orange: #ca4c2f;
$color-orange-light: #e37c53;
$color-orange-heading: #f79d52;
$color-purple: #483d66;
$color-purple-dark: #282840;
$color-purple-darker: #222338;
$color-purple-code: #2d2d48;
$color-purple-border: #474669;
$color-white: #fff;
$color-black: #2a2d38;
$color-gray: #6a7282;
$color-light-gray: #dfe9f0;
$color-dark-gray: #535966;
$color-gray-background: #f0f6fa;
$color-gray-border: #aab7c8;
$color-gray-theme-border: #d8e0e5;
$color-light-gray-border: #d0dfe9;
$color-dot-bg: #ffdede;
$color-dot-line: #fde1ca;
$color-code: #c0c5ce;
$color-code-pink: #b45ea4;
$color-success-text: #58b467;
$color-warning-text: #ff9800;

/* Gradients */
$color-gradient-orange: #f5833d;
$color-gradient-pink: #b54d66;
$gradient-main: linear-gradient(
  45deg,
  $color-gradient-orange 0%,
  $color-gradient-pink 100%
);
$gradient-light: linear-gradient(
  90deg,
  lighten($color-gradient-orange, 3%) 0%,
  lighten($color-gradient-pink, 3%) 100%
);

/* Palettes */
$palette-insurance: (
  primary: #1e5397,
  secondary: #91d8f4,
  tertiary: #4ca2da,
  quaternary: #fff,
);

$palette-marketplace: (
  primary: #e95f5c,
  secondary: #314855,
  tertiary: #79ceb8,
  quaternary: #ffdb00,
);

$palette-personal-finance: (
  primary: #ff3366,
  secondary: #2f7bbd,
  tertiary: #e9ebec,
  quaternary: #323a45,
);

$palette-rental: (
  primary: #479ccf,
  secondary: #96bf48,
  tertiary: #f2f7fa,
  quaternary: #2d3538,
);

$palette-ui-element: (
  insurance: map-get($palette-insurance, primary),
  marketplace: map-get($palette-marketplace, primary),
  personalFinance: map-get($palette-personal-finance, primary),
  rental: darken(map-get($palette-rental, secondary), 10%),
);

$palette-heading: (
  insurance: map-get($palette-insurance, primary),
  marketplace: map-get($palette-marketplace, secondary),
  personalFinance: map-get($palette-personal-finance, quaternary),
  rental: map-get($palette-rental, quaternary),
);

@function _UIColor($palette) {
  @return map-get($palette-ui-element, $key: $palette);
}

@function _headingColor($palette) {
  @return map-get($palette-heading, $key: $palette);
}

@function getColorFromPalette($palette, $colorKey) {
  @if map-has-key($map: $palette, $key: $colorKey) {
    $returnColor: map-get(
      $map: $palette,
      $key: $colorKey,
    );
    @return $returnColor;
  }
  @return null;
}
