@import '../../../assets/styles/colors';
@import '../../../assets/styles/bootstrap-mixins';

.pageNavComponent {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-top: auto;
  padding: 20px 40px;
  z-index: 999;

  @media (max-height: 750px) {
    padding: 15px 30px;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 30px;
  }
}

.logo {
  height: 40px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.buttons {
  flex-shrink: 0;
  margin-left: 15px;
}

.button {
  margin-left: 20px;

  @media (max-height: 750px) {
    margin-left: 10px;
    padding: 8px 19px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 10px;
    padding: 14px 12px;
  }
}

.buttonText {
  @media (max-height: 750px) {
    font-size: 14px;
    line-height: 14px;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.buttonIcon {
  width: 24px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.gradient {
  background-image: $gradient-main;
}

.purple {
  background-color: $color-purple-dark;
}

.white {
  background-color: $color-white;
  border-top: 1px solid $color-light-gray;
}

.pending {
  position: relative;

  span {
    visibility: hidden;
  }
}
