.videoLoopWrap {
  position: relative;
  width: 100%;
  max-width: 860px;
  max-height: 440px;
  min-height: 200px;
  flex-grow: 1;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}
