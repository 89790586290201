@import '../../../assets/styles/variables';
@import '../../../assets/styles/keyframes';

.layersComponent {
  display: flex;
}

.layerWrap {
  perspective: 1500px;
}

.imageWrap {
  position: relative;
  margin: 0 30px;
  transform: rotateY(45deg);
}

.imageFront,
.imageMiddle,
.imageBack {
  position: absolute;
  top: 50%;
  left: 50%;
  transition: opacity 800ms $ease-out-expo;
}

.imageFront {
  max-width: 100%;
  transform: translate(-10%, -50%);
  box-shadow: -95px 77px 60px -80px rgba(0, 0, 0, 0.2);
}

.imageMiddle {
  max-width: 85%;
  transform: translate(-50%, -50%);
  box-shadow: -125px 8px 120px -80px rgba(0, 0, 0, 0.75);
}

.imageBack {
  max-width: 75%;
  transform: translate(-90%, -50%);
  box-shadow: -18px 20px 25px -20px rgba(0, 0, 0, 0.25);
}

.focusedWrap {
  margin: 0 30px;
  position: relative;
}

.imageFocused {
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 8px 120px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  transition: opacity 800ms $ease-out-expo;
}

.expanding {
  .imageWrap {
    transform: rotateY(0);
    animation: wrap 200ms ease-out 1600ms 1 forwards;
  }

  .imageFront {
    margin-left: -40%;
    animation: front 200ms linear 1600ms 1 forwards;
  }

  .imageMiddle {
    transform: translate(-10%, -50%);
    opacity: 0;
    animation: middle 400ms $ease-out-expo 1900ms 1 forwards;
  }

  .imageBack {
    transform: translate(-10%, -50%);
    opacity: 0;
    animation: back 500ms $ease-out-expo 1950ms 1 forwards;
  }
}

.sliding {
  .layerWrap {
    transform: translateX(50%);
    animation: slideLeft 800ms $ease-out-expo 500ms 1 forwards;
  }

  .focusedWrap {
    opacity: 0;
    animation: fadeIn 800ms $ease-out-expo 1300ms 1 forwards;
  }
}

.fadedIn {
  opacity: 1;
}

.fadedOut {
  opacity: 0;
}

@keyframes wrap {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(45deg);
  }
}

@keyframes front {
  0% {
    margin-left: -40%;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes middle {
  0% {
    transform: translate(-10%, -50%);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

@keyframes back {
  0% {
    transform: translate(-10%, -50%);
    opacity: 0;
  }

  100% {
    transform: translate(-90%, -50%);
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0);
  }
}
