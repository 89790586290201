@import '../../../assets/styles/colors';

.textBadgeComponent {
  padding: 4px 6px;
  border-radius: 999px;
  font-size: 10px;
  text-transform: uppercase;
}

.gray {
  color: $color-gray;
  border: 1px solid $color-gray-border;
}
