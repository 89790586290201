@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/keyframes';
@import '../../../assets/styles/bootstrap-mixins';

.labelsComponent {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.label {
  position: absolute;
  display: flex;
}

.back {
  left: -110%;
  top: 31%;
  text-align: right;

  @include media-breakpoint-down(lg) {
    left: -83%;
  }
}

.middle {
  left: -80%;
  top: -7%;
  text-align: right;
}

.front {
  right: -113%;
  top: 40%;

  @include media-breakpoint-down(lg) {
    right: -86%;
  }
}

.text {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: $color-white;
  width: 300px;

  @include media-breakpoint-down(lg) {
    width: 205px;

    &.wide {
      width: 300px;
    }
  }
}

.term {
  font-family: $font-poppins;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.description {
  font-family: $font-roboto;
  line-height: 16px;
  display: none;

  &.selfService {
    display: block;
  }
}

.shapes {
  height: 18px;
  display: flex;
  align-items: center;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: $color-dot-bg;
  opacity: 0.7;
  margin-left: 12px;
  margin-right: 12px;
}

.dashedLine,
.shortDashedLine,
.slantedDashedLine {
  border-top: 1px dashed $color-dot-line;
  height: 1px;
  margin-top: -1px;
}

.dashedLine {
  width: 59px;
}

.shortDashedLine {
  width: 10px;
}

.slantedDashedLine {
  width: 59px;
  transform: rotate(45deg) translate(10px, 20px);
}

.expanding {
  .label {
    opacity: 0;
  }

  .back {
    animation: fadeIn 800ms $ease-out-expo 2300ms 1 forwards;
  }

  .middle {
    animation: fadeIn 800ms $ease-out-expo 2100ms 1 forwards;
  }

  .front {
    animation: fadeIn 800ms $ease-out-expo 2500ms 1 forwards;
  }
}
