@import '../../../assets/styles/colors';

.insurance {
  :global(.no-payouts-stroke) {
    stroke: _UIColor(insurance);
  }
}

.marketplace {
  :global(.no-payouts-stroke) {
    stroke: _UIColor(marketplace);
  }
}

.personalFinance {
  :global(.no-payouts-stroke) {
    stroke: _UIColor(personalFinance);
  }
}

.rental {
  :global(.no-payouts-stroke) {
    stroke: _UIColor(rental);
  }
}
