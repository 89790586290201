@import './colors';

@mixin getThemeColors($colorKey) {
  .insurance {
    background-color: getColorFromPalette($palette-insurance, $colorKey);
  }

  .marketplace {
    background-color: getColorFromPalette($palette-marketplace, $colorKey);
  }

  .personalFinance {
    background-color: getColorFromPalette($palette-personal-finance, $colorKey);
  }

  .rental {
    background-color: getColorFromPalette($palette-rental, $colorKey);
  }
}
